import React, { forwardRef, useImperativeHandle } from 'react'
import { Link, graphql, useStaticQuery, navigate } from 'gatsby'

// import './header.module.scss'
import headerStyles from './header.module.scss'

// https://stackoverflow.com/questions/37949981/call-child-method-from-parent

const Header = forwardRef((props, ref) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    useImperativeHandle(ref, () => ({
        onSwipe (velocity) {
            const routes = [ '/', '/games', '/tools', '/contact']
            const path = global.location.pathname.match(/\/\w*/g)[0]
            const currentIndex = routes.indexOf(path)
            console.log(currentIndex)
            if (velocity > 0.1) {
                // swipe right
                navigate(routes[clamp(0, routes.length-1, currentIndex-1)])
            } else if (velocity < -0.1) {
                // swipe left
                navigate(routes[clamp(0, routes.length-1, currentIndex+1)])
            }
        }
    })) 

    const clamp = (min, max, value) => {
        return Math.min(Math.max(value, min), max)
    }

    return (
        <header className={headerStyles.header}>
            <h1>
                <Link className={headerStyles.title} to="/">
                    {data.site.siteMetadata.title}
                </Link>
            </h1>
            <nav>
                <ul className={headerStyles.navList}>
                    <li>
                        <Link className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            partiallyActive={true}
                            to="/games">
                            Games
                        </Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            partiallyActive={true}
                            to="/tools">
                            Tools
                        </Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem}
                            activeClassName={headerStyles.activeNavItem}
                            partiallyActive={true}
                            to="/contact">
                            Social Platforms
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    )
})

export default Header