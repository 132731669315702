import React, { useRef } from 'react'
import Hammer from 'react-hammerjs'

import Header from './header'
import Footer from './footer'
import '../styles/styles.scss'
import styles from './layout.module.scss'


const Layout = (props) => {

    const childRef = useRef()

    const handleSwipe = (evt) => {
        childRef.current.onSwipe(evt.overallVelocityX)
    }

    return (
        <Hammer onSwipe={handleSwipe}>
            <div className={styles.layout}>
                <div className={styles.sectionHeader}>
                    <div>
                        <Header ref={childRef} />
                    </div>
                </div>
                <div className={styles.sectionMain}>
                    <div>
                        {props.children}
                    </div>
                </div>
                <div className={styles.tree}></div>
                <div className={styles.sectionFooter} >
                    <div>
                        <Footer />
                    </div>
                </div>
            </div>
        </Hammer>
    )
}

export default Layout