import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'

import ExtLink from '../components/extLink'
import footerStyles from './footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                    started
                }
            }
        }
    `)

    const renderYearRange = () => {
        const curYear = new Date().getFullYear()
        const starting = moment(data.site.siteMetadata.started, 'DD-MM-YYYY').year()
        
        if (starting === curYear) {
            return starting
        } 
        return starting + ' - ' + curYear
    }

    return (
        <footer className={footerStyles.footer}>
            <p>Created by {data.site.siteMetadata.author} © {renderYearRange()}</p>
            <p className={footerStyles.legal}>Icons made by <ExtLink to="https://www.flaticon.com/authors/dave-gandy" title="Dave Gandy">Dave Gandy</ExtLink>, <ExtLink to="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</ExtLink>, and <ExtLink to="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</ExtLink> from <ExtLink to="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</ExtLink>                
            </p>
        </footer>
    )
}

export default Footer